import Alpine from 'alpinejs';
import Cookies from 'js-cookie';
import collapse from '@alpinejs/collapse';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import smoothscroll from 'smoothscroll-polyfill';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Cookies
window.Cookies = Cookies;

// Swiper
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

window.Swiper = Swiper;
Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

// Smooth Scroll polyfill for Safari (iOS)
smoothscroll.polyfill();

// GSAP
gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

// Alpine
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();
